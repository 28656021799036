<!--    큐레이션 보낸 의뢰 레이아웃<br>
    <button>의뢰취소</button>-->
<template>
    <div class="mypage_curation_send_wrap">
        <!--       보낸 의뢰 리스트 레이아웃-->
        <table class="my_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
               style = "table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="95px" />
                <col width="143px" />
                <col width="250px" />
                <col width="190px" />
                <col width="155px" />
                <!--                <col width="130px" />-->
                <col width="185px" />
                <col width="140px" />
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{$t('image')}}</th>
                <th colspan="1">{{$t('subject')}}</th>
                <th colspan="1">{{$t('client')}}</th>
                <th colspan="1">{{$t('mini_sell')}}</th>
<!--                <th colspan="1">{{$t('a_curation')}}</th>-->
                <th colspan="1">{{$t('r_date')}}</th>
                <th colspan="1">{{$t('status')}}</th>
            </tr>
            <tr v-for="(curation, index) in curationData" :key="`curation${curation.ibc_idx}`">
                <td class="cursor" @click="moveItemDetail(curation)">{{ tableIdx(index) }}</td>  <!-- No -->
                <td class="cursor" @click="moveItemDetail(curation)">
                    <img :src="returnItemImage(curation)" width="78" height="49"/>
                </td>
                <td class="cursor t_subject" @click="moveItemDetail(curation)">{{ curation.i_name }}</td>  <!-- 아이템명 -->

                <td class="cursor" @click="moveItemDetail(curation)">{{ curation.curator_nick }}</td>  <!-- 큐레이터명 -->

                <td class="cursor" @click="moveItemDetail(curation)">{{ numFormat(curation.ibc_price) }} </td> <!-- 최소 판매금액 -->

<!--                <td>
                    퍼블 들어가시는 분이 삭제해주세요.
                </td> &lt;!&ndash; 큐레이션 비용 &ndash;&gt;-->

                <td class="cursor" @click="moveItemDetail(curation)" v-html="returnDateFormat(curation.created_at, 'YYYY-MM-DD HH:MM')"></td>  <!-- 의뢰일시 -->

                <td>
                    <button v-if="returnStatusData(curation.ibc_status).hasCancel" class="endDate" @click="cancelRequest(curation)">{{$t('cancel_request')}}</button>
                    <span v-else>{{$t(returnStatusData(curation.ibc_status).showName)}}</span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {curationStatus} from "@/service/curationService";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "CurationListSendLayout",
    mixins: [alertMixins, imageOption, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        curationData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            curationStatus: curationStatus,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnItemImage(curation) {
            if(util.isEmpty(curation.ItemFile) || util.isEmpty(curation.ItemFile.Item)) {
                return "";
            }
            return `${curation.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'jpg')}`;
        },
        numFormat(num) {
            if(util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        returnStatusData(value) {
            let status = {value: null, name: '-', showName: '-', hasCancel: false,};
            let index = curationStatus.findIndex(st => st.value === value);
            if(index > -1) {
                status = curationStatus[index];
            }
            return status;
        },
        cancelRequest(curation) {
            this.createConfirm({
                content: " '<span class='subject'>"+ curation.i_name + "</span>'<br>" + this.$t('mypage_cancel_confirm'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('curationListCancel', curation)
                },
            })
        },
        moveItemDetail(curation) {
            let url = `/item/${curation.i_idx}`
            if(curation.im_status === 2 || curation.im_status === 5) {
                url = `/premiummarket/${curation.i_idx}`
            }else if(curation.im_status === 1 || curation.im_status === 3 || curation.im_status === 4) {
                url = `/openmarket/${curation.i_idx}`
            }
            this.$router.push(url);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
